import React, { useEffect, useState, useRef } from "react"
import { css } from "@xstyled/emotion"
import { navigate } from "gatsby-plugin-intl"
import * as Sentry from "@sentry/browser"
import axios from "axios"
import LangContext from "@contexts/lang"
import useSearchParams from "@hooks/useSearchParams"
import Flex from "@components/globals/Flex"
import Box from "@components/globals/Box"
import Typography from "@components/globals/Typography"
import LoadingSvg from "@images/payment-pending.svg"
import PaymentSuccessSvg from "@images/payment-success.svg"
import PaymentFailureSvg from "@images/payment-failure.svg"
import Button from "@components/globals/Button"
import { DEFAULT_COUNTRY_CODE, BE_URL } from "@utils/constants"
import { DASH_URL } from "@utils/constants"
import { gaSetActionPurchase, gaSetCurrency } from "@utils/analytics"
import SEO from "@components/common/seo/seo"
import formatMessage from "@utils/texts"
import useMounted from "@hooks/useMounted"

const ProcessPaymentPage = ({ location }) => {
  const {
    country,
    organization_name: organizationName,
    tap_id: chargeId,
  } = useSearchParams(location)
  const { lang } = React.useContext(LangContext)
  const [isLoading, setIsLoading] = useState(true)
  const [paymentReference, setPaymentReference] = useState()
  const [token, setTokenReference] = useState()
  const [deal_id, setDealIdReference] = useState()
  const [user_id, setUserReference] = useState()
  const [chargeStatus, setChargeStatus] = useState()
  const timeoutRef = useRef(null)
  const hasMounted = useMounted()

  const pageTitle = formatMessage({
    id: "paymentPage.completeRegistration",
    lang,
  })

  useEffect(() => {
    async function getChargeStatus() {
      if (chargeId) {
        try {
          const {
            data: {
              status,
              price,
              currency,
              payment_reference_number: paymentReferenceNumber,
              token: token,
              deal_id: deal_id,
              user_id: user_id,
              lang: lang,
            } = {},
          } = await axios.get(`${BE_URL}/charge_status?charge_id=${chargeId}`)
          setChargeStatus(status)
          setDealIdReference(deal_id)
          if (status === "INITIATED") {
            timeoutRef.current = setTimeout(getChargeStatus, 2000)
          } else {
            if (status === "CAPTURED") {
              setPaymentReference(paymentReferenceNumber)
              setTokenReference(token)
              setUserReference(user_id)
              gaSetCurrency(currency)
              gaSetActionPurchase({
                id: paymentReferenceNumber,
                revenue: price,
              })
              setIsLoading(false)
              setTimeout(() => {
                if (hasMounted)
                  window.location.href = `${DASH_URL}/sign-in?lang=en&token=${token}&user_id=${user_id}`
              }, 5000)
            } else if (status !== "INITIATED") {
              setIsLoading(false)
            }
          }
        } catch (error) {
          Sentry.captureException(error)
          if (!error || !error.response || error.response.status !== 400) {
            timeoutRef.current = setTimeout(getChargeStatus, 2000)
          }
        }
      }
      // TODO: clean up by cancelling request... low priority
      return () => {
        timeoutRef.current && clearTimeout(timeoutRef.current)
      }
    }

    getChargeStatus()
  }, [chargeId])

  return (
    <>
      <SEO location={location} title={pageTitle} />
      <PaymentStatus
        chargeStatus={chargeStatus}
        country={country}
        isLoading={isLoading}
        paymentReference={paymentReference}
        token={token}
        deal_id={deal_id}
        user_id={user_id}
      />
    </>
  )
}

const PaymentStatus = ({
  chargeStatus,
  country,
  isLoading,
  paymentReference,
  token,
  deal_id,
  user_id,
}) => {
  const { lang } = React.useContext(LangContext)
  let heading = formatMessage({ id: "paymentPage.pendingTitle", lang })
  let subheading = formatMessage({ id: "paymentPage.pendingBody", lang })
  let Loader = LoadingSvg
  
  if (!isLoading) {
    if (chargeStatus === "CAPTURED") {
      Loader = PaymentSuccessSvg
      heading = formatMessage({ id: "paymentPage.successTitle", lang })
      subheading = `${formatMessage({
        id: "paymentPage.successBodyEntry",
        lang,
      })} ${paymentReference} ${formatMessage({
        id: "paymentPage.successBody",
        lang,
      })}`
    } else if (chargeStatus !== "INITIATED") {
      Loader = PaymentFailureSvg
      heading = formatMessage({ id: "paymentPage.failureTitle", lang })
      subheading = formatMessage({ id: "paymentPage.failureBody", lang })
    }
  }

  function handleGoBackClick() {
    if (deal_id !== null) {
      navigate("/register/?deal_id=" + deal_id)
    } else {
      navigate("/?country=" + (country || DEFAULT_COUNTRY_CODE))
    }
  }

  return (
    <Flex
      alignItems="center"
      borderRadius="4px"
      border="solid 1px #f2f2f2"
      backgroundColor="#ffffff"
      flexDirection="column"
      px={3}
      py={8}
    >
      {isLoading ? (
        <Loader
          alt="loader"
          style={{ animation: "spin 1.5s linear infinite" }}
        />
      ) : (
        <Box as={Loader} />
      )}

      <Typography mt={2} fontSize="18px" fontWeight="bold" textAlign="center">
        {heading}
      </Typography>
      <Typography color="grays.subtitle" mt={1} multiline textAlign="center">
        {subheading}
        {chargeStatus === "CAPTURED" ? (
          <a
            href={
              DASH_URL +
              "/sign-in?lang=en&token=" +
              token +
              "&user_id=" +
              user_id
            }
          >
            <u>{formatMessage({ id: "paymentPage.clickHere", lang })}</u>
          </a>
        ) : null}
      </Typography>
      {!isLoading &&
      chargeStatus !== "INITIATED" &&
      chargeStatus !== "CAPTURED" ? (
        <Button
          isLoading={isLoading}
          mt={2}
          px={3}
          py={2}
          onClick={handleGoBackClick}
        >
          {deal_id !== null
            ? formatMessage({ id: "paymentPage.tryAgain", lang })
            : formatMessage({ id: "paymentPage.goBack", lang })}
        </Button>
      ) : null}
    </Flex>
  )
}

export default ProcessPaymentPage
